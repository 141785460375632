(function () {
  'use strict';

  /* eslint-plugin-disable angular/johnpapa */
  // set cookie containing global id so that if customer signs out it will be
  // available to add to the non-order survey
  setGlobalIdCookie();

  function setGlobalIdCookie() {
    if (!document.cookie.match(/gi=(\d+)/)) {
      document.cookie = "gi=" + PageClientData.Id + ";domain: .melaleuca.com;path=/";
    }
  }

  (function () {
    checkIfSurvey();

    function setShowSurveyCookie() {
      document.cookie = "showSurvey=true;domain: .melaleuca.com;path=/";
    }

    function getGlobalIdCookie() {
      var gi = checkForGlobalIdCookie();

      if (gi) {
        return gi[1];
      } else {
        return "noID";
      }
    }

    function setHasSeenSurveyCookie() {
      document.cookie = "hasSeenSurvey=true;expires=Tue, 01 Jan 2030 07:00:00 GMT;domain: .melaleuca.com;path=/";
    }

    function checkForHasSeenSurveyCookie() {
      return /hasSeenSurvey=true/.test(document.cookie);
    }

    function checkForGlobalIdCookie() {
      return document.cookie.match(/gi=(\d+)/);
    }

    function checkForShowSurveyCookie() {
      return document.cookie.match(/showSurvey=true/);
    }

    function removeShowSurveyCookie() {
      document.cookie = "showSurvey=true;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain: .melaleuca.com;path=/";
    }

    function addQueryToSurveyPopup(n) {
      var gi = getGlobalIdCookie();

      if ($(".smcx-btn-primary").length) {
        $(".smcx-btn-primary").attr("href", $(".smcx-btn-primary").attr("href") + "?gl=" + gi + "&cu=" + PageClientData.UICulture); // set this cookie so they don't see a survey popup again

        setHasSeenSurveyCookie();
      } else if (n > 20) {
        return;
      } else {
        setTimeout(function () {
          addQueryToSurveyPopup(n + 1);
        }, 500);
      }
    }

    function callSurvey(isPostOrder) {
      //if customer is on PWA
      if (window.isPwa && window.isPwa()) {
        if (isPostOrder) {
          webm_sv.pwa_post_order();
        } else {
          webm_sv.pwa_non_order();
        } //if customer is not on PWA

      } else {
        if (isPostOrder) {
          webm_sv.post_order();
        } else {
          webm_sv.non_order();
        }
      }
    }

    function checkIfSurvey() {
      if (!checkForHasSeenSurveyCookie()) {
        if (checkForShowSurveyCookie()) {
          // customer has just left order receipt page
          // fire code to launch survey if on
          callSurvey(true);
          addQueryToSurveyPopup(1);
          removeShowSurveyCookie();
        } else if (/myaccount\/orderdetails/i.test(location.pathname) && (/productstore\/checkout/i.test(document.referrer) || /ProductStore\/PaymentPortal\/SubmitPayment\/\d+/i.test(document.referrer) || /myaccount\/shoppingreminder/i.test(document.referrer))) {
          // customer is on order receipt page
          setShowSurveyCookie();
          setTimeout(function () {
            callSurvey(true);
            addQueryToSurveyPopup(1);
            removeShowSurveyCookie();
          }, webm_sv.timeBeforeSurvey);
        } else if (/^\/Home$/i.test(location.pathname) && /signedOut=true/i.test(location.search)) {
          // customer has just signed out
          callSurvey(false);
          addQueryToSurveyPopup(1);
        }
      }
    }
  })();

}());
